<template>
	<div data-route>
		<page-header
			heading="Create new course"
		/>
		<div data-element="main">
			<category-edit
				:cancelRoute="getCancelRoute"
				:isCreate="true"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader    from '@/components/ui/PageHeader';
	import CategoryEdit  from '@/components/knowledgebase/edit/CategoryEdit';

	export default {
		metaInfo: {
			title: 'Create new category'
		},
		components: {
			PageHeader,
			CategoryEdit
		},
		data: () => ({}),
		computed: {
			getCancelRoute () {
				return '/admin/knowledgebase/categories';
			}
		},
		created () {},
		methods: {}
	};

</script>
